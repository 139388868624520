/**
 * absolutely_health theme custom JS main file
 */
jQuery(document).ready(function () {
  $('#fullwidth_play').on('click', function (e) {
    e.preventDefault();

    var videoFile = $('#videoplaceholder').data('file');
    var mimeType = $('#videoplaceholder').data('mime');

    var videoHtml = '<video width="100%" controls autoplay>' +
      '<source src="' + videoFile + '" type="' + mimeType + '">' +
      'Your browser does not support the video tag.' +
      '</video>';

    $('#videoplaceholder').html(videoHtml);
    $('.fullwidth-video').find('.content').slideUp('fast');
  });

  /**
   * Mobile Search
   */

  $("#mobile_search_trigger").click(function () {

    // Toggle slide for the search bar
    $("#mobileSearch").slideToggle("fast");

    // Slide up the notice bar if it exists
    if ($("#noticeBar").length) {
      $("#noticeBar").slideToggle("fast");
    }
  });

  /**
   * Mobile Menu Trigger & Function
   */
  const burgerMenubtn = $('#burger');
  const mobileNav = $('.mobile-slide-menu');
  const closeButton = $('.close');

  burgerMenubtn.click(function () {
    if (mobileNav.hasClass('mobile_nav_active')) {
      mobileNav.removeClass('mobile_nav_active');
    } else {
      mobileNav.addClass('mobile_nav_active');
    }
  });
  closeButton.click(function () {
    if (mobileNav.hasClass('mobile_nav_active')) {
      mobileNav.removeClass('mobile_nav_active');
    } else {
      mobileNav.addClass('mobile_nav_active');
    }
  });

  //homeheroslider
  if ($(".blog-feed ").length) {
    //Create slider on load
    blogSlider();
  }

  //copy to clip
  $("#copylink").click(function (e) {
    e.preventDefault;
    var dummy = document.createElement("input"),
      text = window.location.href;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    alert("Post URL copied to clipboard.");
  });


});

document.addEventListener('DOMContentLoaded', function() {
  // Attach click event listeners to all buttons with the "read-more-btn" class
  document.querySelectorAll('.read-more-btn').forEach(function(button) {
      button.addEventListener('click', function() {
          var fullContentDiv = button.closest('.content-summary').querySelector('.full-content');
          var readMoreBtn = button.closest('.content-summary').querySelector('.read-more-btn.more');


          if (fullContentDiv && fullContentDiv.classList.contains('full-content')) {
              if (fullContentDiv.style.display === "none") {
                  // Show the full content
                  fullContentDiv.style.display = "block";
                  // hide read more button
                  readMoreBtn.style.display = "none";
                 
              } else {
                  // Hide the full content and change the button text to "Read more"
                  fullContentDiv.style.display = "none";
                  readMoreBtn.style.display = "inline";
              }
          }
      });
  });
});


jQuery(document).ready(function($) {
  $('.read-more-btn').on('click', function(e) {
      e.preventDefault();
      $(this).next('.hidden-content').slideToggle();
      $(this).text($(this).text() === 'read more' ? 'read less' : 'read more');
  });
});

/*header*/
jQuery(document).ready(function ($) {
  let header = $(".scrolled-nav");
  let lastScrolltop = 0;

  hasScrolled();
  setTimeout(hasScrolled, 800);
  $(window).on("scroll", hasScrolled);

  function hasScrolled() {
    let top = $(window).scrollTop();
    if (top > 500 && top <= lastScrolltop) {
      //setTimeout(addFixed,400);
      //header.addClass('fixed-top');
      header.addClass("fixed-top");
    }

    if (top < 500) {
      header.removeClass("fixed-top");
    }

    if ((header.hasClass("fixed-top") && top > lastScrolltop) || top < 400) {
      //setTimeout(removeFixed, 500);
      header.removeClass("fixed-top");
    }
    lastScrolltop = $(window).scrollTop();
  }

  function addFixed() {
    header.addClass("fixed-top");
  }

  function removeFixed() {
    header.removeClass("fixed-top");
  }
});



if ($('#video-placeholder').length) {
  /**
   * Video Load on Scroll
   */
  document.addEventListener("DOMContentLoaded", function () {
    var observer = new IntersectionObserver(
      function (entries) {
        // Loop over the entries
        entries.forEach(function (entry) {
          // If the element is in view
          if (entry.isIntersecting) {
            // Get the placeholder element
            var videoPlaceholder = document.getElementById("video-placeholder");
            // Create the video element
            var video = document.createElement("video");
            video.autoplay = true;
            video.muted = true;
            video.loop = true;

            // Add the source to the video
            var source = document.createElement("source");
            source.src = videoData.url;
            source.type = videoData.type;
            video.appendChild(source);

            // Replace the placeholder with the video
            videoPlaceholder.innerHTML = "";
            videoPlaceholder.appendChild(video);

            // Unobserve the placeholder so it only loads once
            observer.unobserve(videoPlaceholder);
          }
        });
      }, {
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    // Target the video placeholder
    var videoPlaceholder = document.getElementById("video-placeholder");
    observer.observe(videoPlaceholder);
  });
}
if ($('.insights-slider').length) {
  $('.insights-slider').slick({
    infinite: true,
    slidesToShow: 3.3,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    arrows: false,
    variableHeight: true, // Set variableHeight to true
    responsive: [{
        breakpoint: 1426,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1236,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  });
}



if ($('.quotes-slider').length) {
  var $slider = $('.quotes-slider');
 
  $('.quotes-slider .quote:odd').css('background', 'linear-gradient(194.74deg, rgba(255, 255, 255, 0) 21.56%, rgba(255, 130, 128, 0.2) 89.58%)');
  
  $('.quotes-slider .quote').css('margin-left', '20px');
  
  $slider.slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    autoplay: false,
    arrows: true,
    prevArrow: '.swiper-button-prev',
    nextArrow: '.swiper-button-next',
    adaptiveHeight: true, // This option allows the height to adjust based on content
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  });

 


  // Generate dots
  var $dotsContainer = $('.dots');
  var slideCount = $slider.slick("getSlick").slideCount;

  for (var i = 0; i < slideCount; i++) {
    var $dot = $('<div>').addClass('dot').attr('data-slide', i);
    $dotsContainer.append($dot);
  }

  // Set initial active dot
  $dotsContainer.find('.dot').first().addClass('active-dot');

  // Event listener for dots
  $dotsContainer.on('click', '.dot', function () {
    var slideIndex = $(this).data('slide');
    $slider.slick('slickGoTo', slideIndex);
  });

  // Sync dots with slides
  $slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    $dotsContainer.find('.dot').removeClass('active-dot');
    $dotsContainer.find('.dot[data-slide="' + nextSlide + '"]').addClass('active-dot');
  });


  function updateNavButtons() {
    var currentSlide = $slider.slick('slickCurrentSlide');
    var totalSlides = $slider.slick('getSlick').slideCount;

    if (currentSlide === 0) {
      $('.swiper-button-prev').hide();
    } else {
      $('.swiper-button-prev').show();
    }

    if (currentSlide === totalSlides - 1) {
      $('.swiper-button-next').hide();
    } else {
      $('.swiper-button-next').show();
    }
  }

  // Initialize button states
  updateNavButtons();

  // Update buttons after slide changes
  $slider.on('afterChange', function (event, slick, currentSlide) {
    updateNavButtons();
  });
}

if ($('.team-slider').length) {
  $('.team-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    arrows: true,
    prevArrow: '.previous-btn',
    nextArrow: '.next-btn',
    variableHeight: true, // Set variableHeight to true
    responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  });
}



if ($('.image-slider').length) {
  $('.image-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    arrows: true,
    prevArrow: $('.previous'),
    nextArrow: $('.next-button'),
    variableHeight: true, // Set variableHeight to true
    responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  });

}


$(document).ready(function () {
  if ($('.case-study-slider').length) {
    const caseStudySlickSettings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      variableHeight: true,
      responsive: [{
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
          }
        },
        {
          breakpoint: 720,
          settings: {
            slidesToShow: 1,
          }
        },
      ]
    };

    function initializeSlick(slider) {
      slider.slick({
        ...caseStudySlickSettings,
        prevArrow: slider.next().find('.case-studies-nav-prev'),
        nextArrow: slider.next().find('.case-studies-nav-next')
      });
    }

    $('.case-study-slider').each(function () {
      initializeSlick($(this));
    });

    $('#load-more-case-studies').on('click', function (e) {
      e.preventDefault();

      // Disable button
      $(this).attr('disabled', true);

      const numLoadedPosts = $('.post-item').length;

      $.ajax({
        url: '/wp-admin/admin-ajax.php',
        type: 'POST',
        data: {
          action: 'load_more_case_studies',
          num_loaded_posts: numLoadedPosts
        },
        success: function (response) {
          if (response.success == false) {
            $('#load-more-case-studies').parent().html(response.data.content);
            return;
          } else {
            const $newPosts = $(response);
            $('.normal-posts.row').append($newPosts);

            $newPosts.find('.case-study-slider:not(.slick-initialized)').each(function () {
              initializeSlick($(this));
            });
          }
          // enable button
          $('#load-more-case-studies').attr('disabled', false);
        },
        error: function () {
          console.error('Error loading more case studies.');
        }
      });
    });
  }
});



if ($('.clients-slider').length) {
  $('.clients-slider').slick({
    infinite: true,
    slidesToShow: 6.7,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    arrows: false,
    variableHeight: true, // Set variableHeight to true
    responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 2.7,
        }
      },
    ]
  });
}

// Assuming the arrow spans have the classes .arrow-left and .arrow-right
$('.arrow-left').on('click', function () {
  $slider.slick('prev');
});

$('.arrow-right').on('click', function () {
  $slider.slick('next');
});

function homeSlider() {
  $(".logo-slider").slick({
    // Your slick options go here
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 1200, // For screens less than or equal to 1200px
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992, // For screens less than or equal to 992px
        settings: {
          slidesToShow: 4, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 3, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 668, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 2, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
    ],
  });
}

function searchesSlider() {
  $(".home-hero .searches").slick({
    // Your slick options go here
    dots: false,
    arrows: false,
    infinite: false,
    center: false,
    speed: 300,
    slidesToShow: 1.5,
    slidesToScroll: 1,
  });
}

$(document).ready(function () {
  // Define the URLs for the previous and next pages
  var prevPageUrl = "/";
  var nextPageUrl = "/";

  // Event handler for the "prev" button
  $('.prev').click(function () {
    window.location.href = prevPageUrl;
  });

  // Event handler for the "next" button
  $('.next').click(function () {
    window.location.href = nextPageUrl;
  });
});



function blogSlider() {
  $(".posts-slider").slick({
    // Your slick options go here
    dots: false,
    arrows: false,
    infinite: false,
    speed: 300,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 768, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 1.5, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 668, // For screens less than or equal to 768px
        settings: {
          slidesToShow: 1, // Adjust the number of slides as needed
          slidesToScroll: 1,
        },
      },
    ],
  });
}




function heroCatSlider() {
  $('.categories-slider').slick({
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000, // Adjust the speed of auto-scrolling
    arrows: true,
    prevArrow: $('.left'),
    nextArrow: $('.right'),
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    responsive: [{
        breakpoint: 1024, // Screen width up to 1024px
        settings: {
          slidesToShow: 3, // Show 3 slides for medium-sized screens
        }
      },
      {
        breakpoint: 768, // Screen width up to 768px
        settings: {
          slidesToShow: 2, // Show 2 slides for small-sized screens
        }
      },
      {
        breakpoint: 480, // Screen width up to 480px
        settings: {
          slidesToShow: 1, // Show 1 slide for extra small screens
        }
      }
    ]
  });
}
//accordion
if ($('.faqs').length) {
  if ($('.accordion').length) {
    var allPanels = $('.accordion dd').hide();

    $('.accordion dt > a').click(function () {

      var accordionItem = $(this).parents('.accordion_item');

      if (accordionItem.hasClass('active')) {
        accordionItem.removeClass('active');
      } else {
        accordionItem.addClass('active');
      }

      if ($(this).hasClass('active')) {
        $(this).parent().next().slideUp();
        $(this).removeClass('active');
      } else {
        allPanels.slideUp();
        $('.accordion dt > a').removeClass('active');
        $(this).addClass('active');
        $(this).parent().next().slideDown();
        return false;
      }

    });
  }
}






function easeInOutQuad(t) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

// Function to perform smooth scroll
function smoothScrollToTop(duration) {
  const start = window.scrollY;
  const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

  function scroll() {
    const currentTime = 'now' in window.performance ? performance.now() : new Date().getTime();
    const timeElapsed = currentTime - startTime;
    const progress = Math.min(timeElapsed / duration, 1);
    const ease = easeInOutQuad(progress);
    window.scrollTo(0, start * (1 - ease));

    if (progress < 1) {
      requestAnimationFrame(scroll);
    }
  }

  requestAnimationFrame(scroll);
}

// Store all occurances of the element with the class .scrollToTop
const scrollToTopEls = document.querySelectorAll('.scrollToTop');

// Loop through each element
scrollToTopEls.forEach(el => { // Element is asssigned to "el"


  // Attatch event listener to the element
  el.addEventListener('click', function (event) {
    event.preventDefault(); // Prevent the default anchor click behavior
    smoothScrollToTop(1000); // Set the duration to 1000ms (1 second)
  });
});



$(document).ready(function () {
// Half and half slider

// Store the required elements in variables
var teamBlockTextEl = document.querySelector('.team-block .text');
var teamBlockCultureEl = document.querySelector('.team-block .culture');
var teamBlockSliderWrapperEl = document.querySelector('.team-block .team-block-slider-wrapper .team-slider')



// If check to determine if elements are on the page
if (teamBlockTextEl && teamBlockCultureEl && teamBlockSliderWrapperEl) {
  // If elements are on the page add an event listener to the window
  // to run the padding function on window resize
  window.addEventListener('resize', setTeamBlockPadding);

  // Call padding function on page laod
  setTeamBlockPadding();
}

});

// Our padding function
function setTeamBlockPadding() {
  console.log('Running Team block Reszie');

  var teamBlockTextEl = document.querySelector('.team-block .text');
  var teamBlockCultureEl = document.querySelector('.team-block .culture');
  var teamBlockSliderWrapperEl = document.querySelector('.team-block .team-block-slider-wrapper .team-slider')
  
  
  // Calculation variables
  const teamBlockSliderWrapperElHeight = teamBlockSliderWrapperEl.offsetHeight; // Holds the full height of the slider
  const teamBlockPaddingValue = (teamBlockSliderWrapperElHeight / 2 ) + 40; // Holds the padding size (slider height divided by 2 with a 40px offset)

  // Set the styling to both elements
  teamBlockTextEl.style.paddingBottom = teamBlockPaddingValue + 'px';
  teamBlockCultureEl.style.paddingTop = teamBlockPaddingValue + 'px';


}

/**
 * News load more
 */

$(document).ready(function () {

  if (document.querySelector('.news-block')) {

    const $newsLoadMore = $('.load-more .load-more');

    var numberOfPosts = $newsLoadMore.data('postsloaded');

    var $normalPostsRow = $('#last_post');

    $newsLoadMore.on('click', function (e) {
      e.preventDefault();
      jQuery.ajax({
        type: "post",
        url: '/wp-admin/admin-ajax.php',
        data: {
          action: 'load_more_news_posts',
          numOfPosts: numberOfPosts
        },
        success: function (response) {

          if (typeof response === 'object') {

            if (response.success == false) {
              $('.load-more .load-more').attr('disabled', true)
              $normalPostsRow.append(response.data.content)
              $('.load-more .load-more').remove()
              return false;
            }

          }

          $normalPostsRow.append(response)
          numberOfPosts += 6;

          $('.load-more .load-more').attr('data-postsloaded', numberOfPosts);

        }
      });
    });
  }
});

/**
 * About us slider
 */
$(document).ready(function () {
  var $slider = $('.about-slider');
  var $progress = $('.progress');
  var $current = $progress.find('.current strong');
  var $total = $progress.find('.total');
  var $excerpt = $progress.find('.excerpt');

  // Ensure the total number of slides is set after Slick is initialized
  $slider.on('init', function (event, slick) {
    $total.text(('0' + (slick.slideCount)).slice(-2));
    updateProgress(slick.currentSlide);
  });

  // Initialize Slick slider with the correct button selectors
  $slider.slick({
    prevArrow: '.previous',
    nextArrow: '.next-button',
  });




  // Trigger the init event manually since slick does not call it automatically
  $slider.slick('slickGoTo', 0, true);

  // On beforeChange, update the current slide number and caption
  $slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    updateProgress(nextSlide);
  });

  // Function to update progress numbers and caption
  function updateProgress(index) {
    $current.text(('0' + (index + 1)).slice(-2));
    var $currentSlide = $slider.find('.slick-slide[data-slick-index="' + index + '"]');
    var caption = $currentSlide.data('caption');
    $excerpt.text(caption);
  }
});

if ($('.statistic-slider').length) {

  $('.statistic-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    autoplay: {
      speed: 3000,
      pauseOnHover: true,
    },
    arrows: false,
    variableHeight: true, // Set variableHeight to true
  });


}

console.log('slider');
